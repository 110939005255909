import { FormControl, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ru from "react-phone-input-2/lang/ru.json";

import { MaskedInput } from "react-text-mask";

const StepTwo = ({ register, errors, control }) => {
    return (
        <div className="application-modal_step" key={1}>
            <FormControl
                className="application-modal_question"
                fullWidth
                size="small">
                <span className="application-modal_question_title">
                    Контактный телефон:
                </span>
                <Controller
                    name="contactPhone"
                    control={control}
                    render={({ field }) => (
                        <ReactPhoneInput
                            {...register("contactPhone", { required: true })}
                            {...field}
                            value={field.value} // Set the default country value
                            country="by"
                            onlyCountries={["by", "ru", "lt", "lv", "pl", "ua"]}
                            localization={ru}
                            inputProps={{
                                name: "contactPhone",
                                placeholder: "Начните писать тут...",
                                required: true,
                                autoformat: true,
                            }}
                            onChange={(value) => field.onChange(value)} // Update the field value on change
                        />
                    )}
                />
                {errors.contactPhone && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Email адрес:
                </span>
                <Controller
                    name="contactEmail"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            size="small"
                            type="email"
                            {...register("contactEmail", { required: true })}
                            error={!!errors.contactEmail}
                            helperText={
                                errors.contactEmail && "Обязательное поле"
                            }
                            InputProps={{
                                // eslint-disable-next-line
                                inputComponent: MaskedInput as any,
                                inputProps: {
                                    mask: [
                                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                    ],
                                },
                            }}
                            placeholder="your@mail.by"
                        />
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Город проживания:
                </span>
                <TextField
                    size="small"
                    {...register("city", { required: true })}
                    error={!!errors.city}
                    helperText={errors.city && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Ссылка на Instagram:
                </span>
                <TextField
                    size="small"
                    {...register("socialLink", { required: true })}
                    error={!!errors.socialLink}
                    helperText={errors.socialLink && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Текущая деятельность:
                </span>
                <TextField
                    size="small"
                    placeholder="Место работы/учёбы"
                    {...register("workPlace", { required: true })}
                    error={!!errors.workPlace}
                    helperText={errors.workPlace && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <TextField
                    size="small"
                    placeholder="Специализация"
                    {...register("specialization", { required: true })}
                    error={!!errors.specialization}
                    helperText={errors.specialization && "Обязательное поле"}
                />
            </FormControl>
        </div>
    );
};

export default StepTwo;
