import "./style.scss";
import infoIcon from "../../../img/tickets/info-icon.svg";
import { useEffect, useState, useRef } from "react";
import { useAppDispatch } from "../../../app/hooks";
import {
    openModalBuy,
    openModalMore,
    openModalBonus,
} from "../../../redux/slices/ticketsModalSlice";
import alfaIcon from "../../../img/tickets/alfa.svg";

type TicketProps = {
    // eslint-disable-next-line
    info: any;
    categoryId: number;
};

const Ticket = ({ info, categoryId }: TicketProps) => {
    const {
        id,
        gtm,
        color,
        path_photo,
        title,
        description,
        price,
        status,
        next_prices,
        old_prices,
        operator,
        event_id,
        include = [],
        description_list,
        is_alfa
    } = info;
    const { active, disable } = path_photo;
    const [showAllPrices, setShowAllPrices] = useState(false);

    const dispatch = useAppDispatch();

    const ticketRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const ticket = ticketRef.current;
            if (!ticket) return;

            const rect = ticket.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const itemCount = 4; // Adjust based on the number of items
            const elGap = 32; // Adjust based on the CSS gap

            if (rect.top < windowHeight && rect.bottom > 0) {
                const scrollY = window.scrollY + windowHeight / 2 - rect.top;
                const zoomFactor = Math.max(0.8, 1 - scrollY / windowHeight); // Limit the zoom out factor to 0.8
                const translateY = Math.min(scrollY / 4, elGap * 2); // Limit the translation to twice the gap size

                ticket.style.transform = `scale(${zoomFactor}) translateY(${translateY}px)`;
            }
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleScroll);
        };
    }, []);

    const getBtn = (status: string) => {
        switch (status) {
            case "sold":
            case "soldout":
                return <div className="ticket_btns_sold">Распродано</div>;

            case "soon":
                return (
                    <>
                        <div className="ticket_btns_sold">
                            <span className="ticket_btns_sold_text">Скоро</span>
                        </div>
                    </>
                );

            default:
                if (operator === "url") {
                    return (
                        <a
                            href={event_id}
                            id={color}
                            target="_blank"
                            rel="noreferrer"
                            className="ticket_btns_buy shine">
                            Купить
                        </a>
                    );
                }
                return (
                    <div
                        id={gtm}
                        className="ticket_btns_buy shine"
                        onClick={() =>
                            dispatch(
                                openModalBuy({
                                    category_id: categoryId,
                                    ticket_id: id,
                                })
                            )
                        }>
                        Купить
                    </div>
                );
        }
    };

    const getBtnMore = () => {
        if (description_list && description_list.length > 0) {
            return (
                <div
                    className="ticket_btns_more"
                    onClick={() =>
                        dispatch(
                            openModalMore({
                                category_id: categoryId,
                                ticket_id: id,
                            })
                        )
                    }>
                    Подробнее
                </div>
            );
        } else {
            return <></>;
        }
    };

    const getBtnALfa = () => {
        if (is_alfa) {
            return (
                <div
                    className="ticket_btns_alfa"
                    onClick={() => {
                        window.open("https://www.alfabank.by/viva-braslav/?utm_source=website&utm_medium=vivabraslav.by&utm_campaign=alfabonus_vivabraslav&utm_content=tickets", "_blank");
                    }}>
                    <img
                        src={alfaIcon}
                        alt=""
                        className="ticket_btns_alfa_icon"
                    />
                    Альфа Бонус
                </div>
            );
        } else {
            return <></>;
        }
    };

    const getPrice = (status: string) => {
        switch (status) {
            case "soldout":
                return <div className="ticket_price_text">SOLD OUT</div>;

            case "soonWithoutPrice":
                return null;

            default:
                return (
                    <div
                        className="ticket_price"
                        style={!price ? { display: "none" } : {}}>
                        <div className="ticket_price_current">
                            <div
                                className="ticket_price_current_close"
                                style={
                                    status === "sold"
                                        ? { display: "block" }
                                        : {}
                                }></div>
                            <span className="ticket_price_whole">
                                {price.split(".")[0]}
                            </span>
                            <span className="ticket_price_fractional">
                                {price.split(".")[1]}
                            </span>
                            <span className="ticket_price_currency">Br</span>
                        </div>
                        <div
                            className="ticket_price_all"
                            onClick={() => setShowAllPrices(!showAllPrices)}>
                            <img
                                src={infoIcon}
                                alt="Viva Braslav Цены"
                                className="ticket_price_all_icon"
                                onClick={() => setShowAllPrices(!showAllPrices)}
                            />
                            <div className="ticket_prices">
                                <div
                                    className="ticket_prices_info_block"
                                    style={
                                        showAllPrices
                                            ? {
                                                  opacity: "1",
                                                  pointerEvents: "all",
                                              }
                                            : { pointerEvents: "none" }
                                    }>
                                    {old_prices.map(
                                        (item: string, index: number) => {
                                            return (
                                                <span
                                                    className="ticket_prices_info_item price_old"
                                                    key={index}>
                                                    {item} Br
                                                </span>
                                            );
                                        }
                                    )}
                                    <span
                                        className="ticket_prices_info_item price_now"
                                        style={
                                            status === "sold"
                                                ? {
                                                      textDecoration:
                                                          "line-through",
                                                  }
                                                : {}
                                        }>
                                        {price} Br
                                    </span>
                                    {next_prices.map(
                                        (item: string, index: number) => {
                                            return (
                                                <span
                                                    className="ticket_prices_info_item price_next"
                                                    key={index}>
                                                    {item} Br
                                                </span>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    useEffect(() => {
        const onClick = (e) => {
            if (
                e.target.className !== "ticket_price_all" &&
                e.target.className !== "ticket_price_all_icon"
            ) {
                setShowAllPrices(false);
            }
        };
        document.addEventListener("click", onClick);
        return () => document.removeEventListener("click", onClick);
    }, []);

    const getBanner = () => {
        switch(status) {
            case "sold":
            case "soldout":
            case "soon":
                return disable;
    
            default:
                return active;
        }
    };

    return (
        <div className="ticket" key={id}>
            <div
                className="ticket_background"
                style={{
                    background:
                        "#" +
                        color +
                        " url(https://vivabraslav.by/" +
                        getBanner() +
                        ") center center / cover no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}></div>
            <div
                className="ticket_background_mobile"
                style={{
                    backgroundColor: "#" + color,
                }}></div>
            <div className="ticket_wrapper">
                <div className="ticket_information">
                    <div className="ticket_name">
                        <h4 className="ticket_name_title">Билет "{title}"</h4>
                        <span className="ticket_name_text">{description}</span>
                    </div>
                    {getPrice(status)}
                </div>
                <div className="ticket_btns">
                    {getBtnALfa()}
                    {getBtnMore()}
                    {getBtn(status)}
                </div>
            </div>
        </div>
    );
};

export default Ticket;
