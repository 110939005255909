import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    TextField,
} from "@mui/material";

const StepFour = ({ register, errors, control }) => {
    return (
        <div className="application-modal_step" key={1}>
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Почему хочешь стать волонтером:
                </span>
                <TextField
                    size="small"
                    {...register("motivateText", { required: true })}
                    error={!!errors.motivateText}
                    helperText={errors.motivateText && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Дополнительная информация о тебе:
                </span>
                <TextField
                    size="small"
                    {...register("addInfo")}
                    error={!!errors.addInfo}
                    helperText={errors.addInfo && "Обязательное поле"}
                />
            </FormControl>

            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        {...register("agreeRules", { required: true })}
                    />
                }
                label={
                    <span style={{ fontSize: "12px" }}>
                        Я подтверждаю, что ознакомлен(-а) с{" "}
                        <a
                            href="https://vivabraslav.by/docs/2025/privacy.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "underline",
                                color: "#1976d2",
                            }}>
                            политикой обработки персональных данных
                        </a>{" "}
                        и даю согласие на обработку персональных данных.
                    </span>
                }
            />
            {errors.agreeRules && (
                <FormHelperText error>Обязательное поле</FormHelperText>
            )}
        </div>
    );
};

export default StepFour;
