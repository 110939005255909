import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

import PageTitle from "../../Components/pageTitle";
import "./style.scss";

import step1 from "../../img/route/step1.jpg";
import step2 from "../../img/route/step2.jpg";
import step22 from "../../img/route/step22.jpg";
import step3 from "../../img/route/step3.jpg";
import step4 from "../../img/route/step4.jpg";
import stepTour from "../../img/route/tour.jpg";

import mapGarage from "../../img/route/map_garage.jpg";
import mapCloseRoads from "../../img/route/map_close_roads.jpg";

import ModalWithPanZoom from "./Modal";
import { useLocation } from "react-router-dom";

const HowToGet = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const [modalOpen, setModalOpen] = useState(false);
    const [photoItem, setPhotoItem] = useState(null);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [pathname]);

    const handleOpenModal = (item) => {
        setPhotoItem(item);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    return (
        <>
            <PageTitle
                title="Как добраться на фестиваль"
                subtitle="Короткий гайд с важной информацией"
            />
            <div className="how-to-get">
                <div className="how-to-get__container">
                    <div className="how-to-get__wrapper">
                        <div
                            className="how-to-get__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="how-to-get__item_left">
                                <span className="how-to-get__item_left_count">
                                    1
                                </span>
                                <img
                                    src={step1}
                                    alt="1"
                                    className="how-to-get__item_left_img"
                                />
                            </div>
                            <div className="how-to-get__item_right">
                                <span className="how-to-get__item_right_title">
                                    Изучи схему организации дорожного движения на время проведения фестиваля
                                </span>
                                <span className="how-to-get__item_right_text">
                                    {/* Нажми на кнопку «Посмотреть», чтобы изучить
                                    схему организации дорожного движения.
                                    <br/><br/> */}
                                    <b>При планировании маршрута учитывайте, что в пределах пограничной зоны необходимо иметь при себе документ, удостоверяющий личность.</b>
                                </span>
                                {/* <div className="how-to-get__item_right_btns">
                                    <input
                                        type="button"
                                        value="Посмотреть"
                                        className="how-to-get__item_right_btn"
                                        onClick={() => handleOpenModal(mapCloseRoads)}
                                    />
                                </div> */}
                            </div>
                        </div>
                        <div
                            className="how-to-get__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="how-to-get__item_left">
                                <span className="how-to-get__item_left_count">
                                    2
                                </span>
                                <img
                                    src={step2}
                                    alt="2"
                                    className="how-to-get__item_left_img"
                                />
                            </div>
                            <div className="how-to-get__item_right">
                                <span className="how-to-get__item_right_title">
                                    Заранее построй маршрут, если едешь на авто
                                </span>
                                {/* <span className="how-to-get__item_right_text">
                                    Нажми на кнопку «Посмотреть», чтобы изучить
                                    схему подъезда к локации Авто garage.
                                    <br />
                                    <br />
                                    Нажми на кнопку «Навигатор», чтобы открылся
                                    навигатор с уже установленными координатами
                                    до локации Авто garage.
                                </span> */}
                                {/* <div className="how-to-get__item_right_btns">
                                    <input
                                        type="button"
                                        value="Посмотреть"
                                        className="how-to-get__item_right_btn"
                                        onClick={() => handleOpenModal(mapGarage)}
                                    />
                                    <a
                                        href="https://yandex.by/maps/-/CCUJZIaRkC"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="how-to-get__item_right_btn">
                                        Навигатор
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        {/* <div
                            className="how-to-get__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="how-to-get__item_left">
                                <span className="how-to-get__item_left_count">
                                    3
                                </span>
                                <img
                                    src={step22}
                                    alt="1"
                                    className="how-to-get__item_left_img"
                                />
                            </div>
                            <div className="how-to-get__item_right">
                                <span className="how-to-get__item_right_title">
                                    Заранее построй маршрут, если едешь на кемпере
                                </span>
                                <span className="how-to-get__item_right_text">
                                    Нажми на кнопку «Посмотреть», чтобы изучить
                                    схему подъезда к локации Авто capmer.
                                    <br />
                                    <br />
                                    Нажми на кнопку «Навигатор», чтобы открылся
                                    навигатор с уже установленными координатами
                                    до локации Авто capmer.
                                </span>
                                <div className="how-to-get__item_right_btns">
                                    <input
                                        type="button"
                                        value="Посмотреть"
                                        className="how-to-get__item_right_btn"
                                        onClick={() => handleOpenModal(mapGarage)}
                                    />
                                    <a
                                        href="https://yandex.by/maps/-/CLTqJjB"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="how-to-get__item_right_btn">
                                        Навигатор
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div
                            className="how-to-get__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="how-to-get__item_left">
                                <span className="how-to-get__item_left_count">
                                    4
                                </span>
                                <img
                                    src={step3}
                                    alt="1"
                                    className="how-to-get__item_left_img"
                                />
                            </div>
                            <div className="how-to-get__item_right">
                                <span className="how-to-get__item_right_title">
                                    Позаботься о билете "Авто garage 4 дня"
                                </span>
                                <span className="how-to-get__item_right_text">
                                    {" "}
                                    Позаботься о своём автотранспорте.
                                    Воспользуйся услугой и не переживай за
                                    безопасность своего авто.
                                </span>
                                <div className="how-to-get__item_right_btns">
                                    <a
                                        href="/tickets"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="how-to-get__item_right_btn">
                                        Билеты
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        <div
                            className="how-to-get__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="how-to-get__item_left">
                                <span className="how-to-get__item_left_count">
                                    3
                                </span>
                                <img
                                    src={step4}
                                    alt=""
                                    className="how-to-get__item_left_img"
                                />
                            </div>
                            <div className="how-to-get__item_right">
                                <span className="how-to-get__item_right_title">
                                    Воспользуйся нашим чатом в телеграм для
                                    поиска попутчиков на Viva Braslav
                                </span>
                                <span className="how-to-get__item_right_text">
                                    {" "}
                                    Каждый год люди со всей страны и зарубежья
                                    объединяются и едут вместе на фестиваль. Это
                                    отличный способ найти новые знакомства,
                                    друзей и сократить расходы на дорогу.
                                </span>
                                <div className="how-to-get__item_right_btns">
                                    <a
                                        href="https://t.me/+iHrS9YePubM3NzRi"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="how-to-get__item_right_btn">
                                        Телеграм
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="how-to-get__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="how-to-get__item_left">
                                <span className="how-to-get__item_left_count">
                                    4
                                </span>
                                <img
                                    src={stepTour}
                                    alt=""
                                    className="how-to-get__item_left_img"
                                />
                            </div>
                            <div className="how-to-get__item_right">
                                <span className="how-to-get__item_right_title">
                                    Автобусный тур на Viva Braslav
                                </span>
                                <span className="how-to-get__item_right_text">
                                    {" "}
                                    Отличное решение если не хочешь заниматься
                                    организационными вопросами сам! 
                                </span>
                                <div className="how-to-get__item_right_btns">
                                    <a
                                        href="https://vivabraslav.t9t.by/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="how-to-get__item_right_btn">
                                        Подробнее
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="how-to-get__item" data-aos="fade-up" data-aos-delay="200">
                            <div className="how-to-get__item_left">
                                <span className="how-to-get__item_left_count how-to-get__item_left_count_disable">4</span>
                                <div className="how-to-get__item_left_img"></div>
                            </div>
                            <div className="how-to-get__item_right">
                                <span className="how-to-get__item_right_title how-to-get__item_right_title_disable">Скоро здесь появится информация</span>
                                <div className="how-to-get__item_right_btns">
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <ModalWithPanZoom
                isOpen={modalOpen}
                onClose={handleCloseModal}
                imageUrl={photoItem}
            />
        </>
    );
};

export default HowToGet;
