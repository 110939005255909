import './style.scss';
import closeIcon from '../../../img/tickets/close-modal.svg';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { closeModalBuy, openConfirm, openFinalTicketok, openModalBuy } from '../../../redux/slices/ticketsModalSlice';

const ModalPayment = () => {

    const [testUrl, setConstUrl] = useState("");

    const { selected, isOpenModalBuy, isOpenConfirm, tickets } = useAppSelector((state: RootState) => state.ticketsModalStore);
    const dispatch = useAppDispatch();

    // Функция для открытия виджета BeGateway
    function openBeGateway (event: MessageEvent) {
        const data = event.data;
        if(data.event === "bePaidPayment") {
            const url = new URL(data.link);
            dispatch(closeModalBuy());
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            new window.BeGateway({
                token: data.token, 
                checkout_url: url.origin,
                closeWidget: function(status) {
                    if(status === 'successful') {
                        setConstUrl('https://store.ticketok.by/final?email='+ data.email+'&token=' + data.session_key);
                        dispatch(openFinalTicketok());
                        console.log('https://store.ticketok.by/final?email='+ data.email+'&token=' + data.session_key);
                    }
                }
            }).createWidget();
        }
    }

    // Хук для обработки сообщений из виджета BeGateway
    useEffect(() => {
        window.addEventListener("message", openBeGateway); 
        return () => {
            window.removeEventListener("message", openBeGateway);
        };    
        // eslint-disable-next-line
    }, []);

    // Хук для проверки параметров URL и открытия модального окна
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const modalType = urlParams.get('modal_type');
        const eventIdParam = urlParams.get('event_id');
        const ticketIdParam = urlParams.get('ticket_id');

        if (tickets.length > 0 && modalType === 'remote' && eventIdParam && ticketIdParam) {
            dispatch(openModalBuy({category_id: eventIdParam, ticket_id: ticketIdParam}));
            console.log("Модальное окно открыто с параметрами URL:", { eventIdParam, ticketIdParam, modalType });
            
        } else {
            console.log("Модальное окно не открыто, так как параметры URL не соответствуют условиям.");
            
        }
    }, [dispatch, tickets]);

    const handleOpenConfirm = () => {
        dispatch(openConfirm());
    };

    const handleCloseModay = () => {
        dispatch(closeModalBuy());
        setConstUrl("");
    };

    const { ticket_id, event_id, operator } = selected;

    // Если нет выбранного билета и модальное окно не открыто, вернуть пустую разметку
    if(!ticket_id && !isOpenModalBuy) {
        return (
            <div className="modal-payment">
                <div className="modal-payment_container">
                    <div className="modal-payment_wrapper"></div>
                </div>
            </div>
        );  
    }



    // Логика рендера модального окна в зависимости от оператора
    switch (selected.operator) {
        case 'ticketok':
            if(isOpenModalBuy) {
                if(testUrl) {
                    return (
                        <div className="modal-payment">
                            <div className="modal-payment_container">
                                <div className="modal-payment_wrapper">
                                    <div className="modal-payment_close" onClick={() => handleOpenConfirm()}>
                                        <img src={closeIcon} alt="X" className="modal-payment_close_icon" />
                                    </div>
                                    <div className="test"></div>
                                    <iframe title="Ticketok Modal" src={testUrl}></iframe>
                                </div>
                                <div className="modal-payment_close-window" style={isOpenConfirm ? {opacity: 1, pointerEvents: 'all'} : {}}>
                                    <span className="modal-payment_close-window_text">Вы действительно хотите закрыть окно?</span>
                                    <div className="modal-payment_close-window_btns">
                                        <div className="modal-payment_close-window_cancel" onClick={() => handleOpenConfirm()}>Отменить</div>
                                        <div className="modal-payment_close-window_close" onClick={() => handleCloseModay()}>Закрыть</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-payment_bg" onClick={() => handleOpenConfirm()}></div>
                        </div>
                    ); 
                } else {
                    return (
                        <div className="modal-payment">
                            <div className="modal-payment_container">
                                <div className="modal-payment_wrapper">
                                    <div className="modal-payment_close" onClick={() => handleOpenConfirm()}>
                                        <img src={closeIcon} alt="X" className="modal-payment_close_icon" />
                                    </div>
                                    <div className="test"></div>
                                    <iframe title="Viva Braslav Modal" src={"https://store.ticketok.by/widget/?ticket_id=" + ticket_id + "&event_id=" + event_id}></iframe>
                                </div>
                                <div className="modal-payment_close-window" style={isOpenConfirm ? {opacity: 1, pointerEvents: 'all'} : {}}>
                                    <span className="modal-payment_close-window_text">Вы действительно хотите закрыть окно?</span>
                                    <div className="modal-payment_close-window_btns">
                                        <div className="modal-payment_close-window_cancel" onClick={() => handleOpenConfirm()}>Отменить</div>
                                        <div className="modal-payment_close-window_close" onClick={() => handleCloseModay()}>Закрыть</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-payment_bg" onClick={() => handleOpenConfirm()}></div>
                        </div>
                    ); 
                }
            } else {
                return (
                    <div className="modal-payment">
                        <div className="modal-payment_container">
                            <div className="modal-payment_wrapper"></div>
                        </div>
                    </div>
                );  
            }
        default:
            return null;         
    }
};

export default ModalPayment;
