import { Checkbox, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";


import TextMask, { TextMaskProps } from 'react-text-mask';

const StepOne = ({register, errors, control }) => {

    const TextMaskCustom = React.forwardRef (
        // eslint-disable-next-line
        (props: TextMaskProps, ref: React.ForwardedRef<HTMLInputElement>) => {
          return (
            <TextMask
              ref={(ref) => {return ref}}
              mask={props.mask}
              guide={false}
              placeholderChar={"_"}
              {...props}
            />
          );
        }
    );

    return(
        <div className="application-modal_step" key={0}>
            <TextField
                {...register('appliactionType')}
                name="appliactionType"
                inputProps={{ type: 'hidden' }}
                defaultValue="artists"
                style={{display: "none"}}
            />

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Фамилия, имя, отчество:</span>
                <TextField 
                    size="small" 
                    {...register('fullName', { required: true })}
                    error={!!errors.fullName}
                    helperText={errors.fullName && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Пол:</span>
                <Controller
                    name="sex"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="Мужской" control={<Radio size="small" />} label="Мужской" />
                            <FormControlLabel value="Женский" control={<Radio size="small" />} label="Женский" />
                        </RadioGroup>
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Дата рождения:</span>
               <Controller
                    name="birthday"
                    control={control}
                    render={({ field }) => (
                    
                    <TextField
                        {...field}
                        size="small" 
                        {...register('birthday')}
                        error={!!errors.birthday}
                        helperText={errors.birthday && "Обязательное поле"}
                        InputProps={{
                        // eslint-disable-next-line
                        inputComponent: TextMaskCustom,
                        inputProps: {
                            mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
                        },
                        }}
                        placeholder="__.__.____"
                    />
                    )}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Ник в телеграм:</span>
                <TextField 
                    size="small" 
                    placeholder="@nickname"
                    {...register('contactTelegram', { required: true })}
                    error={!!errors.contactTelegram}
                    helperText={errors.contactTelegram && "Обязательное поле"}
                />
            </FormControl>
           

        </div>
    );   
}

export default StepOne;