import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

import PageTitle from "../../Components/pageTitle";
import "./style.scss";

import step1 from "../../img/route/step1.jpg";
import step2 from "../../img/route/step2.jpg";
import step22 from "../../img/route/step22.jpg";
import step3 from "../../img/route/step3.jpg";
import step4 from "../../img/route/step4.jpg";
import stepTour from "../../img/route/tour.jpg";

import mapGarage from "../../img/route/map_garage.jpg";
import mapCloseRoads from "../../img/route/map_close_roads.jpg";

import { useLocation } from "react-router-dom";

const Contracts = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [pathname]);
    return (
        <>
            <PageTitle
                title="Правила и публичные договоры"
                subtitle="Информация, с которой стоит ознакомиться"
            />
            <div className="contracts">
                <div className="contracts__container">
                    <div className="contracts__wrapper">
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Правила посещения фестиваля Viva&nbsp;Braslav&nbsp;2025
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://docs.google.com/document/d/e/2PACX-1vSLBPDBJT7jeEHr_REl83S3vea7w8sHRJC1iScPmWk6tyvDvajn3QRcYdmkh6uuQQ/pub"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Правила посещения «Вышка&nbsp;camp»
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://docs.google.com/document/d/e/2PACX-1vTuE8ZzTuUHSxZLYW-xG1awU4IrDQR0byY74-GzKxGO97kqDzorHDuLX3y4fnXjyw/pub"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Правила посещения «Поляна&nbsp;camp»
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://docs.google.com/document/d/e/2PACX-1vTRZxZtu7Xj4dz9EdJ7Wodcmiguh_46y1Txm2No4vP1PZFq5PAaAPAfwYe-3MtAaw/pub"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Правила пользования услугой «Авто&nbsp;garage»
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://docs.google.com/document/d/e/2PACX-1vQkWHRLJfZDpER4FyJvCZY_8mQrf6HpDLhTcD8DUftTOp5NZjycKE_SeuMjvQHjPQ/pub"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Правила пользования услугой «Авто&nbsp;camper»
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://docs.google.com/document/d/e/2PACX-1vRtsHeHTHgXp9vT9eSRWd9QJCN6xunECe-Ww7lnYp63Rmi9OmULX_xFnL7u72U-Tw/pub"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Публичный договор на реализацию билетов на мероприятие
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://docs.google.com/document/d/e/2PACX-1vTOTUPwDyLcs13FCz_FWvi9JsXQVh7T_yLUsHk15e68PzEawO8oahqAx6gGSa3Whg/pub"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Публичный договор возмездного оказания услуг
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://docs.google.com/document/d/e/2PACX-1vTksHRJacE4-8y-7XxAlIqaHlMLJaBV1V4X-BAyCHIaml7gWlsSksiYnpOYRIRpHg/pub"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Политика обработки персональных данных
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2025/privacy.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                   Правила посещения Garage camp
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2024/garage-camp-reg.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                   Публичный договор Garage camp
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2024/garage-camp-contract24.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Публичный договор Кемпинг&nbsp;4&nbsp;дня
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2024/public_camping2024.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Публичный договор Глэмпинг&nbsp;comfort&nbsp;4&nbsp;дня
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2024/public_glamping_comfort2024.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Публичный договор Глэмпинг&nbsp;luxury&nbsp;4&nbsp;дня
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2024/public_glamping_luxury2024.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Публичный договор Глэмпинг&nbsp;4&nbsp;дня
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2024/contract_glamp.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Правила посещения Глэмпинг&nbsp;4&nbsp;дня
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2024/reg_glamping.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Публичный договор Авто&nbsp;camper&nbsp;4&nbsp;дня
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2024/public_auto_camper2024.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="contracts__item"
                            data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="contracts__item_right">
                                <span className="contracts__item_right_title">
                                    Публичный договор Авто&nbsp;garage&nbsp;4&nbsp;дня
                                </span>
                                <div className="contracts__item_right_btns">
                                    <a
                                        href="https://vivabraslav.by/docs/2024/public_auto_garage2024.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="contracts__item_right_btn">
                                        Ознакомиться
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contracts;
